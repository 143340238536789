import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import L5logo from '../../../assets/logos/Shorthand SVG.svg';
import useWindowDimensions, {
    MOBILE_WIDTH,
} from '../../../hooks/useWindowDimensions';
import Service from './Service';

function ServiceList() {
    const [t] = useTranslation('services');
    const { isMobile } = useWindowDimensions();

    const services = [
        {
            title: t('web_development.title'),
            description: t('web_development.description'),
        },
        {
            title: t('mobile_development.title'),
            description: t('mobile_development.description'),
        },
    ];
    return (
        <Container>
            <SectionContainer>
                {services.map((service) => (
                    <Service
                        key={service.title}
                        title={service.title}
                        description={service.description}
                    />
                ))}
            </SectionContainer>

            {!isMobile && (
                <CenteredContainer>
                    <Logo src={L5logo} />
                </CenteredContainer>
            )}
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: row;

    margin: -40px 0 0 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
        margin: 0;
    }
`;

const SectionContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const CenteredContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;

const Logo = styled.img`
    margin: 0 0 0 150px;
    width: 500px;
`;

export default ServiceList;
