import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import styled from 'styled-components';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import useWindowDimensions, {
    MOBILE_WIDTH,
} from '../../../hooks/useWindowDimensions';
import {
    selectIsOpened,
    setIsAnimating,
} from '../../../store/contact-form/contactFormSlice';
import { Colors } from '../../../utils/Colors';
import WorkWithUsContent from './WorkWithUsContent';

function WorkWithUs() {
    const isOpened = useAppSelector(selectIsOpened);
    const { width, height } = useWindowDimensions();
    const dispatch = useAppDispatch();

    const animation = () => {
        if (isOpened && width < 1280) {
            return { height, width: width, borderBottomLeftRadius: 0 };
        } else if (isOpened) {
            return { height: '800px', width: '85%' };
        }

        return '';
    };

    const onFormAnimationComplete = () => {
        if (isOpened) {
            dispatch(setIsAnimating(true));
        } else {
            dispatch(setIsAnimating(false));
        }
    };

    return (
        <Container
            animate={animation()}
            transition={{
                duration: 2,
                ease: 'anticipate',
                delayChildren: 1000,
            }}
            onAnimationComplete={onFormAnimationComplete}
        >
            <AnimatePresence>
                {isOpened && <WorkWithUsContent />}
            </AnimatePresence>
        </Container>
    );
}

const Container = styled(motion.div)`
    display: flex;
    justify-content: start;
    align-items: start;
    position: fixed;
    height: 0;
    width: 0;
    max-width: 1400px;

    background-color: ${Colors.secondary};

    z-index: 1000;

    border-bottom-left-radius: 1000px;

    right: 0;
`;

export default WorkWithUs;
