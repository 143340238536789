import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DaySegment } from '../../../enums/DaySegment';
import { Colors } from '../../../utils/Colors';
import { formatTime } from '../../../utils/Dates';
import DaySegmentItem from '../../sections/work-with-us/DaySegmentItem';
import L5Switch, { Option } from '../L5Switch';
import { AvailabilityDate } from './Appointment';
import Cell from './Cell';

export interface Props {
    day: AvailabilityDate;
    selectedTimeSlot: number;
    setSelectedTimeSlot: Dispatch<SetStateAction<number>>;
    setAppointmentDate: Dispatch<SetStateAction<Date | undefined>>;
    primaryColor?: string;
}

function AppointmentTime({
    day,
    selectedTimeSlot,
    setSelectedTimeSlot,
    setAppointmentDate,
    primaryColor,
}: Props) {
    const [t] = useTranslation('workWithUs');
    const [selectedSegment, setSelectedSegment] = useState(DaySegment.MORNING);

    const timeSlots = useMemo(() => {
        if (day[selectedSegment].length) {
            return day[selectedSegment];
        } else if (!day[DaySegment.MORNING].length) {
            setSelectedSegment(DaySegment.AFTERNOON);
            return day[DaySegment.AFTERNOON];
        } else if (!day[DaySegment.AFTERNOON].length) {
            setSelectedSegment(DaySegment.EVENING);
            return day[DaySegment.EVENING];
        }

        return day[selectedSegment];
    }, [day, selectedSegment]);

    const timeSlotSelected = (index: number) => index === selectedTimeSlot;

    const isDisabled = (key: DaySegment) => !day[key].length;

    const options = [
        {
            key: DaySegment.MORNING,
            value: t('morning'),
            disabled: isDisabled(DaySegment.MORNING),
        },
        {
            key: DaySegment.AFTERNOON,
            value: t('afternoon'),
            disabled: isDisabled(DaySegment.AFTERNOON),
        },
        {
            key: DaySegment.EVENING,
            value: t('evening'),
            disabled: isDisabled(DaySegment.AFTERNOON),
        },
    ];

    const onCellClick = (index: number) => {
        setSelectedTimeSlot(index);
        setAppointmentDate(day[selectedSegment][index].startTime);
    };

    useEffect(() => {
        if (day[selectedSegment].length) {
            setAppointmentDate(day[selectedSegment][0].startTime);
            setSelectedTimeSlot(0);
        }
    }, [day, selectedSegment, setAppointmentDate, setSelectedTimeSlot]);

    return (
        <>
            <L5Switch
                options={options}
                selectedOption={selectedSegment}
                setSelectedOption={setSelectedSegment}
                item={(option: Option, selected: boolean) => (
                    <DaySegmentItem option={option} selected={selected} />
                )}
            />

            <TimeSlots>
                {timeSlots.map((time, index) => (
                    <Cell
                        selected={timeSlotSelected(index)}
                        onClick={() => onCellClick(index)}
                        variant={'small'}
                        primaryColor={primaryColor}
                    >
                        <TimeSlot selected={timeSlotSelected(index)}>
                            {formatTime(time.startTime)}
                        </TimeSlot>
                    </Cell>
                ))}
            </TimeSlots>
        </>
    );
}

const TimeSlots = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    margin: 20px 0;
`;

const TimeSlot = styled.p<{ selected: boolean }>`
    color: ${(props) => (props.selected ? Colors.white : Colors.primary)};
`;

export default AppointmentTime;
