import { motion } from 'framer-motion';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { IoClose } from 'react-icons/io5';
import styled from 'styled-components';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { MOBILE_WIDTH } from '../../../hooks/useWindowDimensions';
import { close } from '../../../store/contact-form/contactFormSlice';
import { Colors } from '../../../utils/Colors';

export interface Props {
    children: ReactNode;
}

function LeftSection({ children }: Props) {
    return (
        <Container
            animate={{ opacity: 1 }}
            transition={{
                delay: 1,
                duration: 2,
                ease: 'anticipate',
            }}
        >
            <ContentContainer>{children}</ContentContainer>
        </Container>
    );
}

const Container = styled(motion.div)`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    opacity: 0;
`;

const ContentContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;

    padding: 0 100px;

    @media (max-width: ${MOBILE_WIDTH}px) {
        justify-content: start;
        padding: 0 20px;
    }
`;

export default LeftSection;
