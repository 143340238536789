import { E164Number } from 'libphonenumber-js/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import styled from 'styled-components';

import { useAppSelector } from '../../hooks/useAppSelector';
import { selectValidators } from '../../store/contact-form/contactFormSlice';
import { Colors } from '../../utils/Colors';
import { getCountry } from '../../utils/Country';
import Label from './Label';

interface Props {
    placeholder: string;
    value: E164Number | undefined;
    onChange: (e: E164Number) => void;
}

function StyledPhoneInput({ placeholder, value, onChange }: Props) {
    const [t] = useTranslation('workWithUs');

    const validators = useAppSelector(selectValidators);

    return (
        <Container>
            <LabelContainer>
                <Label large={false} label={`${t('contact_phone')}*`} />

                {!validators.hasValidPhone && (
                    <ErrorText>{t('invalid_phone')}</ErrorText>
                )}
            </LabelContainer>

            <PhoneInput
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                defaultCountry={getCountry()}
            />
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const LabelContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
`;

const ErrorText = styled.p`
    color: ${Colors.error};
    padding-left: 8px;
`;

export default StyledPhoneInput;
