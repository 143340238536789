import { useInView } from 'framer-motion';
import { ReactNode, useRef } from 'react';
import styled from 'styled-components';

interface Props {
    children: ReactNode;
}

function Section({ children }: Props) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
        <StyledSection ref={ref}>
            <span
                style={{
                    opacity: isInView ? 1 : 0,
                    transition: 'ease-in-out 2s',
                }}
            >
                {children}
            </span>
        </StyledSection>
    );
}

const StyledSection = styled.section`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;

    max-width: 1300px;
`;

export default Section;
