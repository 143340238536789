import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { Locale } from '../enums/Locale';
// EN
import featuredClientsEN from '../locale/en/featured-clients.json';
import footerEN from '../locale/en/footer.json';
import growthsquareEN from '../locale/en/growthsquare.json';
import introEN from '../locale/en/intro.json';
import navbarEN from '../locale/en/navbar.json';
import notFoundEN from '../locale/en/not-found.json';
import servicesEN from '../locale/en/services.json';
import teamEN from '../locale/en/team.json';
import workWithUsEN from '../locale/en/work-with-us.json';
// FR
import featuredClientsFR from '../locale/fr/featured-clients.json';
import footerFR from '../locale/fr/footer.json';
import growthsquareFR from '../locale/fr/growthsquare.json';
import introFR from '../locale/fr/intro.json';
import navbarFR from '../locale/fr/navbar.json';
import notFoundFR from '../locale/fr/not-found.json';
import servicesFR from '../locale/fr/services.json';
import teamFR from '../locale/fr/team.json';
import workWithUsFR from '../locale/fr/work-with-us.json';

export const getLanguage = () => {
    const lang = navigator.language;

    if (lang.includes('fr-')) {
        return Locale.FR;
    }

    return Locale.EN;
};

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        supportedLngs: [Locale.EN, Locale.FR],
        fallbackLng: Locale.EN,
        lng: getLanguage(),
        resources: {
            en: {
                navbar: navbarEN,
                featuredClients: featuredClientsEN,
                footer: footerEN,
                intro: introEN,
                services: servicesEN,
                team: teamEN,
                workWithUs: workWithUsEN,
                notFound: notFoundEN,
                growthsquare: growthsquareEN,
            },
            fr: {
                navbar: navbarFR,
                featuredClients: featuredClientsFR,
                footer: footerFR,
                intro: introFR,
                services: servicesFR,
                team: teamFR,
                workWithUs: workWithUsFR,
                notFound: notFoundFR,
                growthsquare: growthsquareFR,
            },
        },
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
