import moment from 'moment';
import 'moment/locale/fr';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Growthsquare from './pages/Growthsquare';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import i18n from './plugins/i18n';
import { GlobalStyles } from './utils/GlobalStyles';

moment.locale(i18n.language);

function App() {
    return (
        <BrowserRouter>
            <GlobalStyles />
            <ToastContainer />

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/ilyes" element={<Home />} />
                <Route path="/yanis" element={<Home />} />
                <Route path="/idyr" element={<Home />} />
                <Route path="/pierrick" element={<Home />} />
                <Route path="/samuel" element={<Home />} />
                <Route path="/jed-mario" element={<Home />} />
                <Route path="/cynthia" element={<Home />} />
                <Route path="/growthsquare" element={<Growthsquare />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
