import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '../../../utils/Colors';

// Only the small variant as been implemented
export type CellVariant = 'small' | 'medium' | 'large';

export interface Props {
    children: ReactNode;
    selected: boolean;
    onClick: () => void;
    variant?: CellVariant;
    disabled?: boolean;
    hidden?: boolean;
    primaryColor?: string;
}

function L5Cell({
    children,
    selected,
    onClick,
    variant,
    disabled,
    hidden,
    primaryColor,
}: Props) {
    if (disabled) {
        return <DisabledCell hidden={hidden}>{children}</DisabledCell>;
    }

    return (
        <Cell
            selected={selected}
            onClick={onClick}
            variant={variant}
            primaryColor={primaryColor}
        >
            {children}
        </Cell>
    );
}

const DisabledCell = styled.div<{ hidden?: boolean }>`
    display: flex;
    flex-direction: column;

    ${(props) => (props.hidden === true ? 'display: none' : '')};

    justify-content: center;
    align-items: center;

    margin: 5px;
    padding: 10px 10px;
    width: 35px;

    border: 1px solid lightgray;
    border-radius: 10px;

    background-color: lightgray;
`;

const Cell = styled.div<{
    selected: boolean;
    variant?: CellVariant;
    primaryColor?: string;
}>`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    margin: 5px;
    padding: 10px 10px;
    width: 35px;

    border: 1px solid lightgray;
    border-radius: 10px;

    background-color: ${(props) =>
        props.selected
            ? `${props.primaryColor ? props.primaryColor : Colors.secondary}`
            : ''};

    transition: all ease-in-out 0.2s;

    &:hover {
        cursor: pointer;
        border: 1px solid white;
    }

    ${({ variant }) =>
        variant === 'small' &&
        css`
            height: 20px;
            padding: 10px 12px;
            width: unset;
        `}
`;

export default L5Cell;
