import { Link } from 'react-scroll';
import styled from 'styled-components';

import { Section } from '../../enums/Section';
import { Colors } from '../../utils/Colors';

interface Props {
    title?: string;
    to: Section;
    onClick: () => void;
    fontSize?: number;
    offset?: number;
}

function NavLink({ title, to, onClick, offset }: Props) {
    return (
        <Container
            to={to}
            activeClass="active"
            spy={true}
            smooth={true}
            delay={200}
            isDynamic={true}
            offset={offset}
            onClick={onClick}
        >
            {title}
        </Container>
    );
}

const Container = styled(Link)`
    border-bottom: 2px solid ${Colors.white};

    transition: all ease-in 0.3s;
    color: ${Colors.primary};

    margin: 10px 0;

    &:hover {
        cursor: pointer;
        opacity: 0.7;
        border-bottom: 2px solid ${Colors.primary};
    }

    &.active {
        border-bottom: 2px solid ${Colors.primary};
    }
`;

export default NavLink;
