import { motion } from 'framer-motion';
import { useState } from 'react';
import styled from 'styled-components';

import Content from '../components/Content';
import Landing from '../components/Landing';
import Footer from '../components/sections/footer/Footer';
import WorkWithUs from '../components/sections/work-with-us/WorkWithUs';
import PageContainer from '../components/ui-elements/PageContainer';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import Navbar from '../navigation/Navbar';
import { close, selectIsOpened } from '../store/contact-form/contactFormSlice';

function Home() {
    const dispatch = useAppDispatch();

    const isWorkWithUsOpened = useAppSelector(selectIsOpened);

    const [animationDone, setAnimationDone] = useState(false);

    function closeWorkWithUs() {
        if (isWorkWithUsOpened) {
            dispatch(close());
        }
    }

    return (
        <PageContainer>
            {animationDone ? (
                <div onClick={closeWorkWithUs}>
                    <Navbar />
                    <Content />
                    <Footer />
                </div>
            ) : (
                <AnimatedLanding
                    animate={{ opacity: 0 }}
                    transition={{ delay: 4, duration: 1, ease: 'easeInOut' }}
                    onAnimationComplete={() => setAnimationDone(true)}
                >
                    <Landing />
                </AnimatedLanding>
            )}
            <WorkWithUs />
        </PageContainer>
    );
}

const AnimatedLanding = styled(motion.div)`
    display: flex;
    flex: 1;
`;
export default Home;
