import React from 'react';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useLocation } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import styled from 'styled-components';

import { loopfiveApi } from '../../../api';
import { BudgetOption } from '../../../enums/BudgetOption';
import { FormFields } from '../../../enums/FormFields';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { MOBILE_WIDTH } from '../../../hooks/useWindowDimensions';
import {
    resetForm,
    selectFormData,
    selectSelectedBudget,
    selectValidators,
    setHasContactPhone,
    setHasEmail,
    setHasFirstName,
    setHasLastName,
    setHasTellUs,
    setHasValidEmail,
    setHasValidFirstName,
    setHasValidLastName,
    setHasValidPhone,
    setHasValidTellUs,
    setIsTakingAppointment,
    setSelectedBudget,
    sethasValidBudget,
    updateForm,
} from '../../../store/contact-form/contactFormSlice';
import { Colors } from '../../../utils/Colors';
import { isEmailValid } from '../../../utils/InputValidation';
import { quoteMail, slackSalesMessage } from '../../../utils/messages';
import { isDev, isFromAds } from '../../../utils/utils';
import PhoneInput from '../../ui-elements/PhoneInput';
import Select from '../../ui-elements/Select';
import TextInput from '../../ui-elements/TextInput';

export interface Props {
    onSubmit?: () => void;
}

function ContactForm({ onSubmit }: Props) {
    const [t] = useTranslation('workWithUs');
    const dispatch = useAppDispatch();

    const validators = useAppSelector(selectValidators);
    const form = useAppSelector(selectFormData);
    const selectedBudget = useAppSelector(selectSelectedBudget);

    const budgetOptions = [
        {
            key: BudgetOption.SELECT_OPTION,
            value: t('select_option'),
            disabled: true,
        },
        { key: BudgetOption.OPTION_1000_5000, value: '1000$ - 5000$' },
        { key: BudgetOption.OPTION_5000_10000, value: '5000$ - 10 000$' },
        { key: BudgetOption.OPTION_10000_50000, value: '10 000$ - 50 000$' },
        { key: BudgetOption.OPTION_50000_200000, value: '50 000$ - 200 000$' },
        {
            key: BudgetOption.OPTION_200000_500000,
            value: '200 000$ - 500 000$',
        },
        { key: BudgetOption.OPTION_500000_PLUS, value: '500 000$ +' },
        { key: BudgetOption.OTHER, value: t('other') },
    ];

    const buttonDisabled =
        form.firstName.trim() === '' ||
        form.lastName.trim() === '' ||
        form.contactPhone === undefined ||
        !isValidPhoneNumber(form.contactPhone) ||
        form.email.trim() === '' ||
        !isEmailValid(form.email) ||
        form.tellUs.length < 50 ||
        selectedBudget === BudgetOption.SELECT_OPTION;

    async function onNext() {
        if (form.firstName.trim() === '') {
            dispatch(setHasFirstName(false));
        }

        if (form.lastName.trim() === '') {
            dispatch(setHasLastName(false));
        }

        if (
            form.contactPhone === undefined ||
            !isValidPhoneNumber(form.contactPhone)
        ) {
            dispatch(setHasContactPhone(false));
            dispatch(setHasValidPhone(false));
        }

        if (form.email === '' || !isEmailValid(form.email)) {
            dispatch(setHasEmail(false));
        }

        if (selectedBudget === BudgetOption.SELECT_OPTION) {
            dispatch(sethasValidBudget(false));
        }

        if (form.tellUs.length < 50) {
            dispatch(setHasTellUs(false));
        }

        if (buttonDisabled) {
            return;
        }

        dispatch(setIsTakingAppointment(true));
    }

    return (
        <Form>
            <Row>
                <TextInput
                    label={`${t('first_name')}*`}
                    placeholder={t('first_name')}
                    onChange={(e) => {
                        dispatch(updateForm({ firstName: e.target.value }));
                        dispatch(setHasFirstName(true));
                        dispatch(
                            setHasValidFirstName(e.target.value.trim() !== '')
                        );
                    }}
                    value={form.firstName}
                    isMissingValue={
                        !validators.hasFirstName ||
                        !validators.hasValidFirstName
                    }
                    field={FormFields.FIRST_NAME}
                />

                <TextInput
                    label={`${t('last_name')}*`}
                    placeholder={t('last_name')}
                    onChange={(e) => {
                        dispatch(updateForm({ lastName: e.target.value }));
                        dispatch(setHasLastName(true));
                        dispatch(
                            setHasValidLastName(e.target.value.trim() !== '')
                        );
                    }}
                    value={form.lastName}
                    isMissingValue={
                        !validators.hasValidLastName || !validators.hasLastName
                    }
                    field={FormFields.LAST_NAME}
                />
            </Row>

            <Row>
                <TextInput
                    label={`${t('email')}*`}
                    placeholder={t('email')}
                    onChange={(e) => {
                        dispatch(updateForm({ email: e.target.value }));
                        dispatch(setHasEmail(true));
                        dispatch(
                            setHasValidEmail(isEmailValid(e.target.value))
                        );
                    }}
                    value={form.email}
                    type={'email'}
                    isMissingValue={
                        !validators.hasEmail || !validators.hasValidEmail
                    }
                    field={FormFields.EMAIL}
                />

                <TextInput
                    label={t('company_name')}
                    placeholder={`${t('company_name')} ${t('optional')}`}
                    onChange={(e) =>
                        dispatch(updateForm({ companyName: e.target.value }))
                    }
                    value={form.companyName}
                />
            </Row>

            <Row>
                <PhoneInput
                    placeholder="Enter phone number"
                    value={form.contactPhone}
                    onChange={(e) => {
                        dispatch(updateForm({ contactPhone: e }));

                        dispatch(setHasValidPhone(isValidPhoneNumber(e)));
                    }}
                />

                <Select
                    options={budgetOptions}
                    value={form.budget}
                    onChange={(e) => {
                        dispatch(
                            setSelectedBudget(
                                budgetOptions[e.target.options.selectedIndex]
                                    .key
                            )
                        );

                        dispatch(updateForm({ budget: e.target.value }));
                        dispatch(
                            sethasValidBudget(
                                budgetOptions[e.target.options.selectedIndex]
                                    .key !== BudgetOption.SELECT_OPTION
                            )
                        );
                    }}
                />
            </Row>

            <Row>
                {selectedBudget === BudgetOption.OTHER ? (
                    <UndesirableLeadContainer>
                        <Text>{t('contact_team')}</Text>
                    </UndesirableLeadContainer>
                ) : (
                    <TextInput
                        label={t('tell_us')}
                        placeholder={t('describe')}
                        onChange={(e) => {
                            dispatch(updateForm({ tellUs: e.target.value }));
                            dispatch(setHasTellUs(true));
                            dispatch(
                                setHasValidTellUs(e.target.value.length >= 50)
                            );
                        }}
                        value={form.tellUs}
                        large={true}
                        isMissingValue={
                            !validators.hasTellUs || !validators.hasValidTellUs
                        }
                        field={FormFields.TELL_US}
                    />
                )}
            </Row>

            {selectedBudget !== BudgetOption.OTHER && (
                <SendButtonContainer>
                    <SendButton onClick={onNext} disabled={buttonDisabled}>
                        {t('next')}
                    </SendButton>
                </SendButtonContainer>
            )}
        </Form>
    );
}

const Form = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    width: 100%;

    @media (max-width: ${MOBILE_WIDTH}px) {
        width: auto;
        padding: 0 10px;
        margin: -20px 0 20px 0;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;

    padding: 0 0 0 0;
    margin: 0 0 0 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
        flex-direction: column;
        margin: 0;
    }
`;

const UndesirableLeadContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    margin: 30px 10px 0 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
        flex-direction: column;
        margin: 30px 0 70px 0;
    }
`;

const Text = styled.div`
    color: ${Colors.white};
    text-align: center;
`;

export const SendButtonContainer = styled.div`
    display: flex;
    justify-content: end;

    padding: 30px 0 0 0;

    width: 100%;

    @media (max-width: ${MOBILE_WIDTH}px) {
        justify-content: center;
        padding: 20px 0;
        margin: 0 0 30px 0;
    }
`;

export const SendButton = styled.div<{ disabled: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10px;
    margin: 0 10px 0 0;
    width: 100px;

    background-color: ${Colors.white};
    border-radius: 10px;

    opacity: ${(props) => (props.disabled ? '0.5' : '1')};

    transition: all ease-in-out 0.3s;

    &:hover {
        opacity: ${(props) => (props.disabled ? '' : '0.5')};
        cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        width: 100%;
        margin: 0;
    }
`;

export default ContactForm;
