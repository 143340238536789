import React from 'react';
import { useTranslation } from 'react-i18next';
import { BsHouse } from 'react-icons/bs';
import { FaRegStar } from 'react-icons/fa';
import { GoShieldCheck } from 'react-icons/go';
import { HiOutlineLightBulb } from 'react-icons/hi';
import styled from 'styled-components';

import { MOBILE_WIDTH } from '../../../hooks/useWindowDimensions';
import { Colors } from '../../../utils/Colors';
import { BenefitType } from './Benefit';
import Benefit from './Benefit';

function BenefitList() {
    const [t] = useTranslation('intro');

    const benefitsRow1: BenefitType[] = [
        {
            icon: <FaRegStar color={Colors.white} size={40} />,
            title: t('benefits.quality.title'),
            text1: t('benefits.quality.text1'),
            text2: t('benefits.quality.text2'),
        },
        {
            icon: <HiOutlineLightBulb color={Colors.white} size={40} />,
            title: t('benefits.innovation.title'),
            text1: t('benefits.innovation.text1'),
            text2: t('benefits.innovation.text2'),
        },
    ];

    const benefitsRow2 = [
        {
            icon: <GoShieldCheck color={Colors.white} size={40} />,
            title: t('benefits.technological.title'),
            text1: t('benefits.technological.text1'),
            text2: t('benefits.technological.text2'),
        },
        {
            icon: <BsHouse color={Colors.white} size={40} />,
            title: t('benefits.home_based.title'),
            text1: t('benefits.home_based.text1'),
            text2: t('benefits.home_based.text2'),
        },
    ];

    return (
        <Container>
            <Row>
                {benefitsRow1.map((benefit: BenefitType) => (
                    <Benefit
                        key={benefit.title}
                        icon={benefit.icon}
                        title={benefit.title}
                        text1={benefit.text1}
                        text2={benefit.text2}
                    />
                ))}
            </Row>

            <Row>
                {benefitsRow2.map((benefit: BenefitType) => (
                    <Benefit
                        key={benefit.title}
                        icon={benefit.icon}
                        title={benefit.title}
                        text1={benefit.text1}
                        text2={benefit.text2}
                    />
                ))}
            </Row>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    margin: 150px 0 50px 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
        flex-direction: column;
        margin: 150px 0 0 0;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: ${MOBILE_WIDTH}px) {
        flex-direction: column;
    }
`;
export default BenefitList;
