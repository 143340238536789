import React from 'react';
import styled from 'styled-components';

import { Colors } from '../../../utils/Colors';
import { Option } from '../../ui-elements/L5Switch';

export interface Props {
    option: Option;
    selected: boolean;
}

function DaySegmentItem({ option, selected }: Props) {
    return (
        <Container selected={selected}>
            <Text selected={selected}>{option.value}</Text>
        </Container>
    );
}

const Container = styled.div<{ selected: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 5px 10px;
    margin: 0 5px;

    height: 30px;

    border-radius: 10px;

    background-color: ${(props) => (props.selected ? Colors.white : '')};

    transition: all ease-in-out 0.3s;
`;

const Text = styled.p<{ selected: boolean }>`
    color: ${(props) => (props.selected ? Colors.primary : 'gray')};
`;

export default DaySegmentItem;
