import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import LogoDarkBlue from '../assets/logos/Dark blue.svg';
import { Colors } from '../utils/Colors';

function NotFound() {
    const [t] = useTranslation('notFound');

    return (
        <Container>
            <Logo
                src={LogoDarkBlue}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
            />
            <Text>{t('not_found')}</Text>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 90vh;
    width: 100%;
`;

const Logo = styled(motion.img)`
    width: 40%;
    opacity: 0;
`;

const Text = styled.p`
    font-size: 30px;
    color: ${Colors.primary};

    margin: 70px 0;
`;

export default NotFound;
