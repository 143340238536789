import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import styled from 'styled-components';

import { Section } from '../../../enums/Section';
import { MOBILE_WIDTH } from '../../../hooks/useWindowDimensions';
import SectionTitle from '../../ui-elements/SectionTitle';
import TeamList from './TeamList';

function Team() {
    const [t] = useTranslation('team');

    return (
        <Container id={Section.TEAM} name={Section.TEAM}>
            <SectionTitle title={t('meet_the_team.title')} />
            <Subtitle>{t('meet_the_team.subtitle')}</Subtitle>

            <TeamList />
        </Container>
    );
}

const Container = styled(Element)`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 0 100px 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
        margin: 0;
        padding: 0 30px 0 30px;
    }
`;

const Subtitle = styled.p`
    font-size: 20px;
    margin: -30px 0 0 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
        margin: 10px 0 50px 0;
        text-align: justify;
    }
`;

export default Team;
