import Avatar from '@mui/material/Avatar';
import { styled as mstyled } from '@mui/system';
import React from 'react';
import styled from 'styled-components';

import { MOBILE_WIDTH } from '../../../hooks/useWindowDimensions';
import { Colors } from '../../../utils/Colors';

export type TeamMemberType = {
    picture: string;
    fullName: string;
    description: string;
    title: string;
    more?: string;
    isJed?: boolean;
};

function TeamMember({
    picture,
    fullName,
    description,
    isJed,
    title,
}: TeamMemberType) {
    return (
        <Container>
            {/*For some obscure reason, Jed picture looks pixelated if we use the avatar from MUI*/}
            {isJed ? (
                <MyAvatar>
                    <Image src={picture} alt={fullName} />
                </MyAvatar>
            ) : (
                <Picture src={picture} alt={fullName} />
            )}

            <Infos>
                <Name>{fullName}</Name>
                <Title>{title}</Title>
                <Text>{description}</Text>
            </Infos>
        </Container>
    );
}

const MyAvatar = styled.div`
    display: flex;

    width: 200px;
    height: 200px;
    margin: 0 20px 0 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
        width: 150px;
        height: 150px;
    }
`;

const Image = styled.img`
    width: 200px;
    height: 200px;
    border-radius: ${200 / 2}px;
    overflow: hidden;
    border: 3px solid ${Colors.primary};
    object-fit: contain;

    @media (max-width: ${MOBILE_WIDTH}px) {
        width: 150px;
        height: 150px;
        object-fit: contain;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    max-width: 400px;

    margin: 0 20px;

    @media (max-width: ${MOBILE_WIDTH}px) {
        margin: 0 0 30px 0;
    }

    z-index: 0;
`;

const Infos = styled.div`
    display: flex;

    flex-direction: column;
    align-items: start;
    justify-content: center;

    height: 150px;

    margin: 0;
`;

const Name = styled.p`
    font-weight: bolder;
    margin: 0 0 10px 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
        font-size: 13px;
    }
`;

const Title = styled.p`
    font-weight: 600;
    margin: 0 0 10px 0;
    font-size: 15px;

    @media (max-width: ${MOBILE_WIDTH}px) {
        font-size: 12px;
    }
`;

const Text = styled.p`
    margin: 0 0 10px 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
        font-size: 12px;
    }
`;

const Picture = mstyled(Avatar)`
    width: 200px;
    height: 200px;
    border: 3px solid ${Colors.primary};
    margin: 0 20px 0 0;
    
    @media (max-width: ${MOBILE_WIDTH}px) {
           width: 150px;
    height: 150px;
    }
`;

export default TeamMember;
