import { motion, useInView } from 'framer-motion';
import React, { ReactNode, useRef } from 'react';
import styled from 'styled-components';

import { MOBILE_WIDTH } from '../../../hooks/useWindowDimensions';
import { Colors } from '../../../utils/Colors';

export type BenefitType = {
    icon: ReactNode;
    title: string;
    text1: string;
    text2: string;
};

function Benefit({ icon, title, text1, text2 }: BenefitType) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
        <Container ref={ref}>
            <span
                style={{
                    opacity: isInView ? 1 : 0,
                    transition: 'all ease-in-out 2s',
                }}
            >
                {icon}
                <Title>{title}</Title>
                <Text>{text1}</Text>
                <Text>{text2}</Text>
            </span>
        </Container>
    );
}

const Container = styled(motion.div)`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    background-color: ${Colors.primary};

    height: 25vh;

    padding: 30px;
    margin: 20px;

    border-radius: 20px;

    @media (max-width: ${MOBILE_WIDTH}px) {
        width: 250px;
        min-height: 350px;
    }
`;

const Title = styled.p`
    color: ${Colors.white};
    font-size: 25px;

    margin: 20px 0 0 0;
`;

const Text = styled.p`
    color: ${Colors.white};
    font-size: 16px;
`;

export default Benefit;
