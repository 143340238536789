import { motion } from 'framer-motion';
import { useState } from 'react';
import styled from 'styled-components';

import LogoWhite from '../assets/logos/white darker blue.svg';
import { Colors } from '../utils/Colors';

function Landing() {
    const [showLogo, setShowLogo] = useState(false);

    return (
        <Container
            animate={{ backgroundColor: `${Colors.primary}` }}
            transition={{ duration: 1.75, ease: 'anticipate' }}
            onAnimationComplete={() => setShowLogo(true)}
        >
            {showLogo && (
                <Logo
                    src={LogoWhite}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                />
            )}
        </Container>
    );
}

const Logo = styled(motion.img)`
    width: 50%;
    opacity: 0;
`;

const Container = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${Colors.white};
    flex: 1;
`;
export default Landing;
