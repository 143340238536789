import moment from 'moment';

import { Locale } from '../enums/Locale';
import i18n from '../plugins/i18n';

export const formatDate = (date: Date) => {
    return moment(date).format('dddd, MMMM Do');
};

export const formatDayNumber = (date: Date) => {
    return moment(date).format('DD');
};

export const formatDayLetter = (date: Date) => {
    return moment(date).format('ddd');
};

export const formatMonth = (date: Date) => {
    return moment(date).format('MMMM');
};

export const formatYear = (date: Date) => {
    return moment(date).format('YYYY');
};

export const formatMonthAndDay = (date: Date) => {
    return moment(date).format('MMMM DD');
};

export const formatTime = (date: Date) => {
    return i18n.language === Locale.EN
        ? moment(date).format('H:mm A')
        : moment(date).format('HH:mm');
};

export const getDay = (date: Date) => {
    return moment(date).day();
};

export const getNextWeekDay = (date: Date) => {
    const startDate = moment(date);

    if (startDate.day() >= 1 && startDate.day() <= 5) {
        startDate.add(1, 'days');
    }

    while (startDate.day() === 0 || startDate.day() === 6) {
        startDate.add(1, 'days');
    }

    return startDate.day();
};

export const isWeekend = (date: Date) => {
    return moment(date).day() === 0 || moment(date).day() === 6;
};

export const isSameDay = (firstDate: Date, secondDate: Date) => {
    const first = moment(firstDate);
    const second = moment(secondDate);

    return first.isSame(second, 'day');
};

export const hasDayPassed = (date: Date) => {
    const inputDate = moment(date).startOf('day');

    const today = moment().startOf('day');

    return inputDate.isBefore(today);
};

export const withMoreMinutes = (date: Date, minutes: number): Date => {
    return moment(date).add(minutes, 'm').toDate();
};
