import React from 'react';
import styled from 'styled-components';

export interface Props {
    margin?: string;
}

function Separator({ margin }: Props) {
    return <Container margin={margin} />;
}

const Container = styled.div<{ margin?: string }>`
    width: 100%;
    border-top: 1px solid lightgray;
    margin: ${(props) => (props.margin ? props.margin : '')};
`;

export default Separator;
