import React from 'react';
import { CircleLoader } from 'react-spinners';
import styled from 'styled-components';

export enum LoaderSize {
    xsmall = 20,
    small = 30,
    medium = 50,
    large = 70,
    xlarge = 90,
}

export interface Props {
    color: string;
    size?: LoaderSize;
}

function L5Loader({ color, size = LoaderSize.medium }: Props) {
    return (
        <Container>
            <CircleLoader color={color} size={size} />
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;

export default L5Loader;
