import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import styled from 'styled-components';

import { Section } from '../../../enums/Section';
import { MOBILE_WIDTH } from '../../../hooks/useWindowDimensions';

export interface Props {
    children: ReactNode;
}

function RightSection({ children }: Props) {
    return (
        <Container id={Section.WORK_WITH_US} name={Section.WORK_WITH_US}>
            <AnimatedDiv
                animate={{ opacity: 1 }}
                transition={{
                    delay: 1,
                    duration: 2,
                    ease: 'anticipate',
                }}
            >
                {children}
            </AnimatedDiv>
        </Container>
    );
}

const Container = styled(Element)`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: start;

    max-width: 600px;
    padding: 0 2em 0 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
        flex: 10;
        max-width: 1000px;
        padding: 0;
    }
`;

const AnimatedDiv = styled(motion.div)`
    opacity: 0;
`;

export default RightSection;
