import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import styled from 'styled-components';

import { Section } from '../../../enums/Section';
import SectionTitle from '../../ui-elements/SectionTitle';
import ClientsList from './ClientsList';

function FeaturedClients() {
    const [t] = useTranslation('featuredClients');

    return (
        <Container
            id={Section.FEATURED_CLIENTS}
            name={Section.FEATURED_CLIENTS}
        >
            <SectionTitle title={t('featured_clients')} />

            <ClientsList />
        </Container>
    );
}

const Container = styled(Element)`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
`;

export default FeaturedClients;
