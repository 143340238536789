import { FaFacebook, FaLinkedin } from 'react-icons/fa';
import styled from 'styled-components';

import { Social } from '../../../enums/Social';
import { MOBILE_WIDTH } from '../../../hooks/useWindowDimensions';
import { Colors } from '../../../utils/Colors';

function Footer() {
    const navigateToSocial = (social: Social) => {
        window.open(social, '_blank');
    };

    return (
        <Container>
            <SectionContainer>
                <TextWithSeparator>+1 (514) 929-0356</TextWithSeparator>

                <Link href={'mailto:service@loopfive.ca'}>
                    service@loopfive.ca
                </Link>
            </SectionContainer>

            <SectionContainer>
                <TextWithSeparator>© Loopfive Inc. 2024</TextWithSeparator>

                <SocialsContainer>
                    <FacebookIcon
                        color={Colors.white}
                        size={20}
                        onClick={() => navigateToSocial(Social.FACEBOOK)}
                    />

                    <LinkedInIcon
                        color={Colors.white}
                        size={20}
                        onClick={() => navigateToSocial(Social.LINKED_IN)}
                    />
                </SocialsContainer>
            </SectionContainer>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;

    padding: 0 100px;
    min-height: 60px;

    background-color: ${Colors.primary};
    border: 1px solid ${Colors.primary};

    @media (max-width: ${MOBILE_WIDTH}px) {
        padding: 0 20px;
        flex-direction: column;
    }
`;

const SectionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    @media (max-width: ${MOBILE_WIDTH}px) {
        flex-direction: column;
    }
`;

const Link = styled.a`
    text-decoration: none;
    color: ${Colors.white};

    transition: all ease-in-out 0.3s;

    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`;

const TextWithSeparator = styled.p`
    color: ${Colors.white};
    border-right: 2px solid ${Colors.white};

    padding-right: 20px;
    margin-right: 20px;

    @media (max-width: ${MOBILE_WIDTH}px) {
        border: none;
        padding: 0;
        margin-right: 0;
    }
`;

const SocialsContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${MOBILE_WIDTH}px) {
        padding-bottom: 20px;
    }
`;

const FacebookIcon = styled(FaFacebook)`
    color: ${Colors.white};

    transition: all ease-in-out 0.3s;

    margin: 0 15px 0 0;

    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`;

const LinkedInIcon = styled(FaLinkedin)`
    color: ${Colors.white};

    transition: all ease-in-out 0.3s;

    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`;

export default Footer;
