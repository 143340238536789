import React from 'react';
import styled from 'styled-components';

import { MOBILE_WIDTH } from '../../../hooks/useWindowDimensions';

export type ServiceType = {
    title: string;
    description: string;
};

function Service({ title, description }: ServiceType) {
    return (
        <Container>
            <Title>{title}</Title>
            <Text>{description}</Text>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    @media (max-width: ${MOBILE_WIDTH}px) {
        margin: 0 30px 0 0;
    }
`;

const Title = styled.p`
    font-size: 40px;
    margin: 20px 0 0 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
        font-size: 20px;
    }
`;

const Text = styled.p`
    font-size: 20px;
    text-align: justify;

    @media (max-width: ${MOBILE_WIDTH}px) {
        font-size: 15px;
    }
`;

export default Service;
