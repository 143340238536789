import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { DaySegment } from '../../enums/DaySegment';

export type Option = {
    key: any;
    value: any;
    disabled: boolean;
};

export interface Props {
    options: Option[];
    selectedOption: DaySegment;
    setSelectedOption: Dispatch<SetStateAction<DaySegment>>;
    item: (option: Option, selected: boolean) => JSX.Element;
}

function L5Switch({ options, selectedOption, setSelectedOption, item }: Props) {
    const isSelected = (key: DaySegment) => {
        return selectedOption === key;
    };

    const onPress = (key: DaySegment, disabled: boolean) => {
        if (disabled) {
            return;
        }

        setSelectedOption(key);
    };

    return (
        <Container>
            {options.map((option, index) => (
                <Item
                    onClick={() => onPress(option.key, option.disabled)}
                    key={option.key}
                    disabled={option.disabled}
                >
                    {item(option, isSelected(option.key))}
                </Item>
            ))}
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: lightgray;

    padding: 5px 0;

    border-radius: 10px;
`;

const Item = styled.div<{ disabled?: boolean }>`
    &:hover {
        cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    }
`;

export default L5Switch;
