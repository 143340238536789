import { motion } from 'framer-motion';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { MOBILE_WIDTH } from '../../../hooks/useWindowDimensions';
import { Colors } from '../../../utils/Colors';

export type ClientType = {
    businessName: string;
    logo: ReactNode;
    description: string;
};

function Client({ logo, description }: ClientType) {
    return (
        <Container>
            {logo}
            <Text>{description}</Text>
        </Container>
    );
}

const Container = styled(motion.div)`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    background-color: ${Colors.primary};

    height: 23vh;

    padding: 30px;
    margin: 20px 20px 0 0;

    border-radius: 20px;

    @media (max-width: ${MOBILE_WIDTH}px) {
        width: 250px;
        min-height: 350px;
    }
`;

const Text = styled.p`
    color: ${Colors.white};
    font-size: 16px;
`;

export default Client;
