export enum BudgetOption {
    OPTION_1000_5000 = 'OPTION_1000_5000',
    OPTION_5000_10000 = 'OPTION_5000_10000',
    OPTION_10000_50000 = 'OPTION_10000_50000',
    OPTION_50000_200000 = 'OPTION_50000_200000',
    OPTION_200000_500000 = 'OPTION_200000_500000',
    OPTION_500000_PLUS = 'OPTION_500000_PLUS-with-us',
    OTHER = 'OTHER',
    SELECT_OPTION = 'SELECT_OPTION',
}
