import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { MOBILE_WIDTH } from '../../hooks/useWindowDimensions';
import { Colors } from '../../utils/Colors';

function Banner() {
    const [t] = useTranslation('growthsquare');

    return (
        <Container>
            <Text>{t('banner')}</Text>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 20px;

    width: 100%;

    background-color: ${Colors.primary};

    @media (max-width: ${MOBILE_WIDTH}px) {
        width: auto;
    }
`;

const Text = styled.p`
    color: ${Colors.white};
    font-weight: normal;
    letter-spacing: 1px;
    text-align: center;
`;

export default Banner;
