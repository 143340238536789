import React, { Dispatch, SetStateAction, useState } from 'react';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import styled from 'styled-components';

import useWindowDimensions, {
    MOBILE_WIDTH,
} from '../../../hooks/useWindowDimensions';
import { Colors } from '../../../utils/Colors';
import {
    formatDayLetter,
    formatDayNumber,
    formatMonthAndDay,
    hasDayPassed,
    isWeekend,
} from '../../../utils/Dates';
import { AvailabilityDate, WeekAvailabilities } from './Appointment';
import Cell from './Cell';

export interface Props {
    dates: WeekAvailabilities[];
    setSelectedWeek: Dispatch<SetStateAction<number>>;
    setSelectedDay: Dispatch<SetStateAction<number>>;
    selectedDay: number;
    selectedWeek: number;
    primaryColor?: string;
}

function RowCalendar({
    dates,
    setSelectedDay,
    setSelectedWeek,
    selectedDay,
    selectedWeek,
    primaryColor,
}: Props) {
    const { isMobile } = useWindowDimensions();
    const [week, setWeek] = useState(selectedWeek);

    const isEndOfCalendar = week === dates.length - 1;
    const isStartOfCalendar = week === 0;

    const isSelected = (dayIndex: number) => {
        return week === selectedWeek && selectedDay === dayIndex;
    };

    const scrollRight = () => {
        if (!isEndOfCalendar) {
            setWeek(week + 1);
        }
    };

    const scrollLeft = () => {
        if (!isStartOfCalendar) {
            setWeek(week - 1);
        }
    };

    const hasAvailabilitiesToday = (date: AvailabilityDate) =>
        !!date.morning.length ||
        !!date.afternoon.length ||
        !!date.evening.length;

    const hasLowAvailability = (date: AvailabilityDate) => {
        const availabilities = [
            ...date.morning,
            ...date.afternoon,
            ...date.evening,
        ];

        /*
         * There are 22 time slots in a day
         * If the number of availabilities is lower than half of this,
         * Then the calendar is deemed to have low availability
         */
        return availabilities.length < 11;
    };

    const hasNoAvailabilitiesToday = (date: AvailabilityDate) =>
        !date.morning.length && !date.afternoon.length && !date.evening.length;

    const onDayClick = (index: number) => {
        setSelectedDay(index);
        setSelectedWeek(week);
    };

    return (
        <Calendar>
            <Week>
                {formatMonthAndDay(dates[week].weekStart)} -{' '}
                {formatMonthAndDay(dates[week].weekEnd)}
            </Week>

            <Days>
                {!isMobile && (
                    <LeftArrow
                        color={Colors.secondary}
                        size={50}
                        onClick={scrollLeft}
                    />
                )}

                <Row>
                    {dates[week].days.map((date, index) => {
                        return (
                            <Cell
                                key={index}
                                onClick={() => onDayClick(index)}
                                selected={isSelected(index)}
                                disabled={
                                    isWeekend(date.date) ||
                                    hasDayPassed(date.date)
                                }
                                hidden={isMobile}
                                primaryColor={primaryColor}
                            >
                                <DayCellWeekDay selected={isSelected(index)}>
                                    {formatDayLetter(date.date)}
                                </DayCellWeekDay>

                                <DayCellDate selected={isSelected(index)}>
                                    {formatDayNumber(date.date)}
                                </DayCellDate>

                                <HasAvailabilities
                                    hasAvailabilities={hasAvailabilitiesToday(
                                        date
                                    )}
                                    hasLowAvailability={hasLowAvailability(
                                        date
                                    )}
                                    hasNoAvailability={hasNoAvailabilitiesToday(
                                        date
                                    )}
                                    disabled={
                                        isWeekend(date.date) ||
                                        hasDayPassed(date.date)
                                    }
                                ></HasAvailabilities>
                            </Cell>
                        );
                    })}
                </Row>

                <MobileDiv>
                    {isMobile && (
                        <LeftArrow
                            color={Colors.secondary}
                            size={50}
                            onClick={scrollLeft}
                        />
                    )}

                    <RightArrow
                        color={Colors.secondary}
                        size={50}
                        onClick={scrollRight}
                    />
                </MobileDiv>
            </Days>
        </Calendar>
    );
}

const Calendar = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    justify-content: space-between;
    align-items: center;
`;

const DayCellWeekDay = styled.p<{ selected: boolean }>`
    margin: 0;
    text-transform: capitalize;
    color: ${(props) => (props.selected ? Colors.white : '')};
`;

const DayCellDate = styled.p<{ selected: boolean }>`
    margin: 2px 2px 7px 5px;
    color: ${(props) => (props.selected ? Colors.white : '')};
`;

const HasAvailabilities = styled.div<{
    hasAvailabilities: boolean;
    hasLowAvailability: boolean;
    hasNoAvailability: boolean;
    disabled?: boolean;
}>`
    height: 10px;
    width: 25px;

    background-color: ${(props) =>
        props.hasAvailabilities && !props.disabled ? '#07bc0c' : 'red'};

    ${(props) =>
        props.hasLowAvailability &&
        !props.hasNoAvailability &&
        'background-color: orange;'}

    border-radius: 10px;

    border: 1px solid white;
`;

const RightArrow = styled(RiArrowRightSLine)`
    color: ${Colors.secondary};
    transition: all ease-in-out 0.3s;

    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`;

const LeftArrow = styled(RiArrowLeftSLine)`
    color: ${Colors.secondary};
    transition: all ease-in-out 0.3s;

    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`;

const Week = styled.p`
    text-transform: capitalize;
    font-weight: normal;
    letter-spacing: 1px;
`;

const Days = styled.div`
    display: flex;
    flex: 1;

    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: ${MOBILE_WIDTH}px) {
        flex-direction: column;
    }
`;

const MobileDiv = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: ${MOBILE_WIDTH}px) {
        flex: 1;
        justify-content: space-between;
        width: 70%;
        margin: 15px 0;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export default RowCalendar;
