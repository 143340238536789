import React, { ChangeEvent, HTMLInputTypeAttribute } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FormFields } from '../../enums/FormFields';
import useWindowDimensions, {
    MOBILE_WIDTH,
} from '../../hooks/useWindowDimensions';
import { Colors } from '../../utils/Colors';
import Label from './Label';

export type TextInputType = {
    label: string;
    placeholder?: string;
    value: string;
    onChange: (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
    ) => void;
    large?: boolean;
    type?: HTMLInputTypeAttribute;
    isMissingValue?: boolean;
    field?: string;
};

function TextInput({
    label,
    placeholder,
    value,
    onChange,
    large,
    isMissingValue,
    field,
}: TextInputType) {
    const [t] = useTranslation('workWithUs');

    const { isMobile } = useWindowDimensions();

    function errorMessage() {
        switch (field) {
            case FormFields.FIRST_NAME:
            case FormFields.LAST_NAME:
                return t('required_field');
            case FormFields.EMAIL:
                return t('invalid_email');
            case FormFields.TELL_US:
                return t('invalid_tell_us', { characters: 50 - value.length });
            default:
                return '';
        }
    }

    return (
        <Container>
            <LabelContainer>
                <Label large={large} label={label} />

                {isMissingValue && !(large && isMobile) && (
                    <ErrorText>{errorMessage()}</ErrorText>
                )}
            </LabelContainer>

            {large ? (
                <>
                    <InputArea
                        placeholder={placeholder}
                        onChange={(e) => onChange(e)}
                        isMissingValue={isMissingValue}
                        value={value}
                    />

                    {isMissingValue && large && isMobile && (
                        <ErrorText>{errorMessage()}</ErrorText>
                    )}
                </>
            ) : (
                <Input
                    placeholder={placeholder}
                    onChange={(e) => onChange(e)}
                    isMissingValue={isMissingValue}
                    value={value}
                />
            )}
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    min-width: 300px;
`;

const LabelContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
`;

const ErrorText = styled.p`
    color: ${Colors.error};
    padding-left: 8px;
`;

const Input = styled.input<{ isMissingValue: boolean | undefined }>`
    border-radius: 5px;
    border: ${(props) =>
        props.isMissingValue
            ? `1px solid ${Colors.red}`
            : `1px solid ${Colors.white}`};
    height: 40px;
    outline: none;
    padding: 0 0 0 10px;
    margin: 0 10px 0 0;
    transition: all ease-in-out 0.5s;

    @media (max-width: ${MOBILE_WIDTH}px) {
        margin: 0;
    }
`;

const InputArea = styled.textarea<{ isMissingValue: boolean | undefined }>`
    border-radius: 5px;
    border: ${(props) =>
        props.isMissingValue
            ? `1px solid ${Colors.red}`
            : `1px solid ${Colors.white}`};
    height: 200px;
    outline: none;
    padding: 10px 0 0 10px;
    margin: 0 10px 0 0;
    font-family: 'Poppins', sans-serif;
    resize: none;
    transition: all ease-in-out 0.5s;

    @media (max-width: ${MOBILE_WIDTH}px) {
        height: 90px;
        margin: 0;
    }
`;

export default TextInput;
