import appointment from '../components/ui-elements/appointment/Appointment';
import { getLanguage } from '../plugins/i18n';
import { ContactFormData } from '../store/contact-form/contactFormSlice';
import { formatDate, formatTime } from './Dates';

export const quoteMail = (
    form: ContactFormData,
    seller: string,
    appointmentDate: Date
): string => {
    return `<p>Hello Loopfive!</p>
            <p>&nbsp;</p>
            <p>You got a new project request from ${form.companyName} coming from our beloved ${seller}<br><br></p>
            <p>They took an appointment on ${formatDate(appointmentDate)} at ${formatTime(appointmentDate)} </p>            <p><br><br>Here are the information about the request:<br><br>First Name: ${form.firstName}</p>
            <p>Last Name: ${form.lastName}</p>
            <p>Phone Number: ${form.contactPhone}</p>
            <p>Email: ${form.email}</p>
            <p>Language: ${getLanguage().toUpperCase()}</p>
            <p>&nbsp;</p>
            <p style="padding: 12px; border-left: 4px solid #d0d0d0; font-style: italic;">${form.tellUs}</p>
            <p>&nbsp;</p>
            <p>Best wishes, go get it!</p>
           `;
};

export const slackSalesMessage = (
    form: ContactFormData,
    seller: string,
    appointmentDate: Date
): string => {
    return `        Hello Loopfive!

                    \n\nYou got a new project request from ${form.companyName} coming from our beloved ${seller}
                    
                    \n\nThey took an appointment on ${formatDate(appointmentDate)} at ${formatTime(appointmentDate)}

                    \n\nHere are the informations about the request:
                    
                    \n\nBudget: ${form.budget}

                    \n\nFirst Name: ${form.firstName}

                    \n\nLast Name: ${form.lastName}

                    \n\nPhone Number: ${form.contactPhone}

                    \n\nEmail: ${form.email}
                    
                    \n\nDescription: ${form.tellUs}
                    
                    \n\nLanguage: ${getLanguage().toUpperCase()}
                    `;
};
