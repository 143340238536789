import { createSlice } from '@reduxjs/toolkit';
import { E164Number } from 'libphonenumber-js/core';

import { BudgetOption } from '../../enums/BudgetOption';
import { RootState } from '../store';

export type ContactFormData = {
    firstName: string;
    lastName: string;
    email: string;
    companyName: string;
    contactPhone: E164Number | undefined;
    tellUs: string;
    budget: string;
    selectedBudget: BudgetOption;
};

export type Validators = {
    hasContactForm: boolean;
    hasEmail: boolean;
    hasValidEmail: boolean;
    hasFirstName: boolean;
    hasValidFirstName: boolean;
    hasLastName: boolean;
    hasValidLastName: boolean;
    hasTellUs: boolean;
    hasValidTellUs: boolean;
    hasValidPhone: boolean;
    hasValidBudget: boolean;
};

export interface ContactFormState {
    isOpened: boolean;
    form: ContactFormData;
    validators: Validators;
    isTakingAppointment: boolean;
    isLoading: boolean;
    isAnimating: boolean;
}

const initialState: ContactFormState = {
    isOpened: false,
    isTakingAppointment: false,
    isLoading: false,
    isAnimating: false,
    form: {
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        contactPhone: undefined,
        tellUs: '',
        budget: 'none',
        selectedBudget: BudgetOption.SELECT_OPTION,
    },
    validators: {
        hasContactForm: true,
        hasEmail: true,
        hasValidEmail: true,
        hasFirstName: true,
        hasValidFirstName: true,
        hasLastName: true,
        hasValidLastName: true,
        hasTellUs: true,
        hasValidTellUs: true,
        hasValidPhone: true,
        hasValidBudget: true,
    },
};

export const contactFormSlice = createSlice({
    name: 'contactForm',
    initialState,
    reducers: {
        open: (state) => {
            state.isOpened = true;
        },
        close: (state) => {
            state.isOpened = false;
            state.validators = initialState.validators;
            state.isAnimating = false;
        },
        updateForm: (state, action) => {
            state.form = {
                ...state.form,
                ...action.payload,
            };
        },
        resetForm: (state) => {
            state.form = initialState.form;
            state.isTakingAppointment = false;
        },
        setHasFirstName: (state, action) => {
            state.validators.hasFirstName = action.payload;
        },
        setHasValidFirstName: (state, action) => {
            state.validators.hasValidFirstName = action.payload;
        },
        setHasLastName: (state, action) => {
            state.validators.hasLastName = action.payload;
        },
        setHasValidLastName: (state, action) => {
            state.validators.hasValidLastName = action.payload;
        },
        setHasContactPhone: (state, action) => {
            state.validators.hasContactForm = action.payload;
        },
        setHasEmail: (state, action) => {
            state.validators.hasEmail = action.payload;
        },
        setHasValidEmail: (state, action) => {
            state.validators.hasValidEmail = action.payload;
        },
        setHasValidPhone: (state, action) => {
            state.validators.hasValidPhone = action.payload;
        },
        setSelectedBudget: (state, action) => {
            state.form.selectedBudget = action.payload;
        },
        setHasTellUs: (state, action) => {
            state.validators.hasTellUs = action.payload;
        },
        setHasValidTellUs: (state, action) => {
            state.validators.hasValidTellUs = action.payload;
        },
        setIsTakingAppointment: (state, action) => {
            state.isTakingAppointment = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsAnimating: (state, action) => {
            state.isAnimating = action.payload;
        },
        sethasValidBudget: (state, action) => {
            state.validators.hasValidBudget = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    open,
    close,
    updateForm,
    setHasFirstName,
    setHasValidFirstName,
    setHasLastName,
    setHasValidLastName,
    setHasContactPhone,
    setHasEmail,
    setIsAnimating,
    setHasValidEmail,
    setHasValidPhone,
    setIsLoading,
    setIsTakingAppointment,
    setSelectedBudget,
    setHasTellUs,
    setHasValidTellUs,
    sethasValidBudget,
    resetForm,
} = contactFormSlice.actions;

export const selectIsOpened = (state: RootState) => state.contactForm.isOpened;
export const selectFormData = (state: RootState) => state.contactForm.form;
export const selectValidators = (state: RootState) =>
    state.contactForm.validators;
export const selectSelectedBudget = (state: RootState) =>
    state.contactForm.form.selectedBudget;

export const selectIsTakingAppointment = (state: RootState) =>
    state.contactForm.isTakingAppointment;

export const selectIsFormLoading = (state: RootState) =>
    state.contactForm.isLoading;

export const selectIsFormAnimating = (state: RootState) =>
    state.contactForm.isAnimating;

export default contactFormSlice.reducer;
