import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { loopfiveApi } from '../api';
import LogoDarkBlue from '../assets/logos/Dark blue.svg';
import L5Logo from '../assets/logos/Shorthand SVG.svg';
import Banner from '../components/growthsquare/Banner';
import FeaturedClients from '../components/sections/featured-clients/FeaturedClients';
import Footer from '../components/sections/footer/Footer';
import ContactForm from '../components/sections/work-with-us/ContactForm';
import WorkWithUs from '../components/sections/work-with-us/WorkWithUs';
import L5button from '../components/ui-elements/L5button';
import PageContainer from '../components/ui-elements/PageContainer';
import SectionTitle from '../components/ui-elements/SectionTitle';
import Appointment from '../components/ui-elements/appointment/Appointment';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import useWindowDimensions, {
    MOBILE_WIDTH,
} from '../hooks/useWindowDimensions';
import {
    open,
    selectFormData,
    selectIsTakingAppointment,
    setIsTakingAppointment,
} from '../store/contact-form/contactFormSlice';
import { Colors } from '../utils/Colors';
import { isDev, isFromAds } from '../utils/utils';

function Growthsquare() {
    const [t] = useTranslation('growthsquare');
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const { isMobile } = useWindowDimensions();

    const isTakingAppointment = useAppSelector(selectIsTakingAppointment);
    const form = useAppSelector(selectFormData);

    const [isSubmitted, setSubmitted] = useState(false);
    const [version, setVersion] = useState<'A' | 'B'>('A');

    useEffect(() => {
        const version = Math.floor(Math.random() * 101) < 50 ? 'A' : 'B';
        setVersion(version);

        if (!isDev() && isFromAds(location)) {
            loopfiveApi.sendMetaEvent(`PageView - ${version}`);
        }
    }, []);

    const onFormSubmit = async () => {
        setSubmitted(true);
        setTimeout(() => {
            navigate('/');
        }, 1000);
    };

    return (
        <PageContainer>
            <Container
                animate={isSubmitted ? { opacity: 0 } : { opacity: 1 }}
                transition={{
                    duration: 1,
                    ease: 'easeInOut',
                }}
            >
                <Banner />
                <Content>
                    <Logo src={LogoDarkBlue} />
                    <Title>{t('title')}</Title>
                    <Subtitle>{t('subtitle')}</Subtitle>

                    {version === 'A' ? (
                        <L5button
                            text={t('work_with_us')}
                            onClick={() => dispatch(open())}
                            style={{ marginTop: '20px' }}
                        />
                    ) : (
                        <FormSection>
                            <SectionTitle
                                title={'Work with us'}
                                style={{ padding: '20px' }}
                            />
                            <Form>
                                {isTakingAppointment ? (
                                    <Appointment
                                        onSubmit={onFormSubmit}
                                        primaryColor={Colors.primary}
                                        width={isMobile ? '' : '1000px'}
                                    />
                                ) : (
                                    <ContactForm
                                        onSubmit={() =>
                                            dispatch(
                                                setIsTakingAppointment(true)
                                            )
                                        }
                                    />
                                )}
                            </Form>
                        </FormSection>
                    )}

                    <FeaturedClients />
                </Content>
            </Container>
            <WorkWithUs />
        </PageContainer>
    );
}

const Container = styled(motion.div)`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    opacity: 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
        padding: 0;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    max-width: 1500px;
    padding: 60px 20px;

    @media (max-width: ${MOBILE_WIDTH}px) {
        flex-direction: column;
        width: auto;
        padding: 0;
    }
`;

const Logo = styled.img`
    object-fit: contain;
    width: 50%;
    margin: 20px 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
        margin: 80px 0 0 0;
    }
`;

const Title = styled.p`
    font-size: 50px;
    text-align: center;
    margin: 50px 0 0 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
        font-size: 150%;
        padding: 0 20px;
        text-align: unset;
    }
`;

const Subtitle = styled.p`
    font-size: 25px;
    font-weight: lighter;
    margin: 60px 0;
    text-align: center;

    @media (max-width: ${MOBILE_WIDTH}px) {
        font-size: 125%;
        padding: 20px 20px;
        margin: 0;
        text-align: unset;
    }
`;

const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 40px;

    border-radius: 20px;

    background-color: ${Colors.primary};

    @media (max-width: ${MOBILE_WIDTH}px) {
        max-height: 1000px;
        border-radius: 0;

        padding: 40px 0;
    }
`;

export default Growthsquare;
