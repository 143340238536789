import axios from 'axios';

import {
    AvailabilityDate,
    WeekAvailabilities,
} from '../components/ui-elements/appointment/Appointment';
import { ContactFormData } from '../store/contact-form/contactFormSlice';

const api = axios.create({
    baseURL: process.env.REACT_APP_LOOPFIVE_SERVICE_URL,
});

export type MailData = {
    from: string;
    to: string;
    subject: string;
    html: string;
};

export type CalendarResponse = {
    data: WeekAvailabilities[];
};

export type CalendarEventTime = {
    dateTime: Date;
    timeZone: string;
};

export type CalendarAttendee = {
    email: string;
};

export type CalendarEvent = {
    summary: string;
    description: string;
    start: CalendarEventTime;
    end: CalendarEventTime;
    attendees: CalendarAttendee[];
};

export default {
    async sendToSales(text: string) {
        return api.post(`/slack/sales`, { text });
    },
    async sendMail(data: MailData) {
        return api.post('/mail/send', data);
    },
    async sendMetaEvent(event: string) {
        return api.post('/meta-events-manager/send', { event });
    },
    async sendGrowthsquareLead(lead: ContactFormData) {
        return api.post('/growthsquare/create-lead', lead);
    },
    async fetchCalendar(): Promise<CalendarResponse> {
        return api.get('/google-api/calendar');
    },
    async sendMeetingInvites(event: CalendarEvent) {
        return api.post('/google-api/invite', event);
    },
};
