import { Drawer } from '@mui/material';
import { Twirl as Hamburger } from 'hamburger-react';
import { useState } from 'react';
import styled from 'styled-components';

import { Colors } from '../../utils/Colors';
import BrowserNav from './BrowserNav';

function MobileNav() {
    const [toggled, setToggled] = useState(false);

    return (
        <>
            <Hamburger
                color={Colors.primary}
                toggled={toggled}
                toggle={setToggled}
            />

            <Drawer
                open={toggled}
                onClose={() => setToggled(false)}
                anchor={'bottom'}
                elevation={0}
                ModalProps={{
                    slotProps: {
                        backdrop: { sx: { background: 'none' } },
                    },
                }}
                PaperProps={{
                    sx: {
                        height: 'calc(100% - 48px)',
                        top: 48,
                        borderTop: 1,
                        borderColor: Colors.primary,
                    },
                }}
            >
                <LinksContainer>
                    <BrowserNav closeDrawer={() => setToggled(false)} />
                </LinksContainer>
            </Drawer>
        </>
    );
}

const LinksContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export default MobileNav;
