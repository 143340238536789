import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Ilyes from '../../../assets/pictures/Ilyes.jpeg';
import Jed from '../../../assets/pictures/jed.jpg';
import Marty from '../../../assets/pictures/marty.jpeg';
import Pierrick from '../../../assets/pictures/pierrick.jpg';
import Samuel from '../../../assets/pictures/samuel.jpeg';
import { MOBILE_WIDTH } from '../../../hooks/useWindowDimensions';
import TeamMember, { TeamMemberType } from './TeamMember';

function TeamList() {
    const [t] = useTranslation('team');

    const teamMembersRow1: TeamMemberType[] = [
        {
            picture: Ilyes,
            fullName: t('teamMembers.ilyes.fullName'),
            title: t('teamMembers.ilyes.title'),

            description: t('teamMembers.ilyes.description'),
            more: t('teamMembers.ilyes.more'),
        },
        {
            picture: Samuel,
            fullName: t('teamMembers.samuel.fullName'),
            title: t('teamMembers.samuel.title'),

            description: t('teamMembers.samuel.description'),
            more: t('teamMembers.samuel.more'),
        },
    ];

    const teamMembersRow2: TeamMemberType[] = [
        {
            picture: Pierrick,
            fullName: t('teamMembers.pierrick.fullName'),
            title: t('teamMembers.pierrick.title'),

            description: t('teamMembers.pierrick.description'),
            more: t('teamMembers.pierrick.more'),
        },
        {
            picture: Jed,
            fullName: t('teamMembers.jed.fullName'),
            title: t('teamMembers.jed.title'),

            description: t('teamMembers.jed.description'),
            more: t('teamMembers.jed.more'),
            isJed: true,
        },
    ];

    return (
        <Container>
            <Row>
                {teamMembersRow1.map((member) => (
                    <TeamMember
                        key={member.fullName}
                        picture={member.picture}
                        fullName={member.fullName}
                        title={member.title}
                        description={member.description}
                    />
                ))}
            </Row>

            <Row>
                {teamMembersRow2.map((member) => (
                    <TeamMember
                        key={member.fullName}
                        picture={member.picture}
                        fullName={member.fullName}
                        title={member.title}
                        description={member.description}
                        isJed={member.isJed}
                    />
                ))}
            </Row>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 1300px;

    @media (max-width: ${MOBILE_WIDTH}px) {
        min-width: 0;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin: 70px 0 0 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
        flex-direction: column;
        align-items: start;

        margin: 0;
    }
`;

export default TeamList;
