import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import styled from 'styled-components';

import { Section } from '../../../enums/Section';
import { MOBILE_WIDTH } from '../../../hooks/useWindowDimensions';
import SectionTitle from '../../ui-elements/SectionTitle';
import ServiceList from './ServiceList';

function Services() {
    const [t] = useTranslation('services');

    return (
        <Container id={Section.SERVICES} name={Section.SERVICES}>
            <SectionTitle title={t('services')} />

            <ServiceList />
        </Container>
    );
}

const Container = styled(Element)`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    @media (max-width: ${MOBILE_WIDTH}px) {
        margin: 0 0 0 30px;
    }
`;

export default Services;
