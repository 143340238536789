import styled from 'styled-components';

import FeaturedClients from './sections/featured-clients/FeaturedClients';
import Intro from './sections/intro/Intro';
import Services from './sections/services/Services';
import Team from './sections/team/Team';
import Section from './ui-elements/Section';

const content = [<Intro />, <Services />, <FeaturedClients />, <Team />];

function Content() {
    return (
        <Container>
            {content.map((section, index) => {
                return <Section key={index}>{section}</Section>;
            })}
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    margin: 50px 0 0 0;
`;

export default Content;
