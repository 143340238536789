import styled from 'styled-components';

import ShortHandL5 from '../assets/logos/Shorthand SVG.svg';
import useWindowDimensions, {
    MOBILE_WIDTH,
} from '../hooks/useWindowDimensions';
import { Colors } from '../utils/Colors';
import BrowserNav from './components/BrowserNav';
import MobileNav from './components/MobileNav';

function Navbar() {
    const { isMobile } = useWindowDimensions();

    return (
        <Container>
            <Nav>
                <Logo src={ShortHandL5} />

                {isMobile ? <MobileNav /> : <BrowserNav />}
            </Nav>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
    position: fixed;

    background-color: ${Colors.white};
    z-index: 100;
`;

const Nav = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;

    max-width: 700px;

    @media (max-width: ${MOBILE_WIDTH}px) {
        padding: 0 10px 0 10px;
    }
`;

const Logo = styled.img`
    width: 5%;
    min-width: 50px;
`;

export default Navbar;
