import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import styled from 'styled-components';

import { loopfiveApi } from '../../../api';
import { CalendarEvent, CalendarResponse } from '../../../api/loopfiveService';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { MOBILE_WIDTH } from '../../../hooks/useWindowDimensions';
import {
    close,
    resetForm,
    selectFormData,
    selectIsFormLoading,
    setIsLoading,
} from '../../../store/contact-form/contactFormSlice';
import { Colors } from '../../../utils/Colors';
import {
    getNextWeekDay,
    isSameDay,
    isWeekend,
    withMoreMinutes,
} from '../../../utils/Dates';
import { quoteMail, slackSalesMessage } from '../../../utils/messages';
import { isDev, isFromAds } from '../../../utils/utils';
import Loader, { LoaderSize } from '../L5Loader';
import Separator from '../Separator';
import AppointmentTime from './AppointmentTime';
import RowCalendar from './RowCalendar';

export type Availability = {
    startTime: Date;
    endTime: Date;
};

export type AvailabilityDate = {
    date: Date;
    morning: Availability[];
    afternoon: Availability[];
    evening: Availability[];
};

export type WeekAvailabilities = {
    weekStart: Date;
    weekEnd: Date;
    days: AvailabilityDate[];
};

export interface Props {
    onSubmit?: () => void;
    primaryColor?: string;
    width?: string;
    version?: 'A' | 'B'; // for A/B testing
}

function Appointment({ onSubmit, primaryColor, width, version }: Props) {
    const [t] = useTranslation('workWithUs');
    const location = useLocation();
    const dispatch = useAppDispatch();

    const form = useAppSelector(selectFormData);
    const isLoading = useAppSelector(selectIsFormLoading);

    const [weekAvailabilities, setWeekAvailabilities] = useState<
        WeekAvailabilities[]
    >([]);
    const [selectedDay, setSelectedDay] = useState(5);
    const [selectedWeek, setSelectedWeek] = useState(0);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(0);
    const [appointmentDate, setAppointmentDate] = useState<Date>();

    const getCalendarAvailabilities = async () => {
        dispatch(setIsLoading(true));
        const today = new Date();

        try {
            const response: CalendarResponse =
                await loopfiveApi.fetchCalendar();

            setWeekAvailabilities(response.data);

            if (isWeekend(today)) {
                setSelectedDay(getNextWeekDay(today));
                setSelectedWeek(selectedWeek + 1);
            } else {
                todayIndex(response.data[0].days);
            }
        } catch (e) {
            console.log(e);
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    const todayIndex = (days: AvailabilityDate[]) => {
        days.map((day, index) => {
            const date = new Date(day.date);
            const today = new Date();

            if (isSameDay(date, today)) {
                setSelectedDay(index);
            }
        });
    };

    const selectedDayAvailabilities = () => {
        return weekAvailabilities[selectedWeek].days[selectedDay];
    };

    const onBookAppointment = async () => {
        dispatch(setIsLoading(true));

        if (!appointmentDate) {
            return;
        }

        const seller = window.location.pathname.replace('/', '') || 'website';
        const mailData = {
            from: 'no-reply@loopfive.ca',
            to: 'service@loopfive.ca',
            subject: 'New project request',
            html: quoteMail(form, seller, appointmentDate),
        };

        const meetingData: CalendarEvent = {
            summary: t('meeting.summary'),
            description: t('meeting.description'),
            start: {
                dateTime: appointmentDate,
                timeZone: 'America/Toronto',
            },
            end: {
                dateTime: withMoreMinutes(appointmentDate, 30),
                timeZone: 'America/Toronto',
            },
            attendees: [
                { email: form.email },
                { email: 'idyrhadji88@gmail.com' },
            ],
        };

        try {
            if (!isDev()) {
                if (isFromAds(location)) {
                    await loopfiveApi.sendGrowthsquareLead(form);
                    await loopfiveApi.sendMetaEvent(
                        `Envoyer Candidature - ${version}`
                    );
                }

                await loopfiveApi.sendToSales(
                    slackSalesMessage(form, seller, appointmentDate)
                );

                await loopfiveApi.sendMail(mailData);
                await loopfiveApi.sendMeetingInvites(meetingData);
            }

            toast.success(t('email_sent'), {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        } catch (e) {
            console.log(e);
        } finally {
            dispatch(setIsLoading(false));
        }

        if (onSubmit) {
            onSubmit();
        }

        dispatch(resetForm());
        dispatch(close());
    };

    useEffect(() => {
        getCalendarAvailabilities();
    }, []);

    if (!weekAvailabilities.length) {
        return (
            <LoaderContainer>
                <Loader color={Colors.white} />
            </LoaderContainer>
        );
    }

    return (
        <Container width={width}>
            {isFromAds(location) && <Title>{t('select_time_slot')}</Title>}

            <RowCalendar
                dates={weekAvailabilities}
                setSelectedWeek={setSelectedWeek}
                setSelectedDay={setSelectedDay}
                selectedDay={selectedDay}
                selectedWeek={selectedWeek}
                primaryColor={primaryColor}
            />

            <Separator margin={'20px 0'} />

            <AppointmentTime
                day={selectedDayAvailabilities()}
                selectedTimeSlot={selectedTimeSlot}
                setSelectedTimeSlot={setSelectedTimeSlot}
                setAppointmentDate={setAppointmentDate}
                primaryColor={primaryColor}
            />

            <BookAppointmentButton
                onClick={onBookAppointment}
                isLoading={isLoading}
                primaryColor={primaryColor}
            >
                {isLoading ? (
                    <Loader color={Colors.white} size={LoaderSize.small} />
                ) : (
                    <Text>{t('book')}</Text>
                )}
            </BookAppointmentButton>
        </Container>
    );
}

const LoaderContainer = styled.div`
    display: flex;
    flex: 1;

    min-height: 500px;
`;

const Container = styled.div<{ width?: string }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 15px;

    border-radius: 10px;

    background-color: white;

    user-select: none;

    width: ${(props) => (props.width ? props.width : '')};

    @media (max-width: ${MOBILE_WIDTH}px) {
        border-radius: 0;
    }
`;

const BookAppointmentButton = styled.div<{
    isLoading: boolean;
    primaryColor?: string;
}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    border-radius: 10px;

    background-color: ${(props) =>
        props.primaryColor ? props.primaryColor : Colors.secondary};

    transition: all ease-in-out 0.3s;

    min-height: 60px;

    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        margin-bottom: 30px;
    }
`;

const Title = styled.p`
    padding: 0 10px;
    text-align: center;
`;

const Text = styled.p`
    color: ${Colors.white};
    letter-spacing: 2px;
`;

export default Appointment;
