import { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
    children: ReactNode;
}

function PageContainer({ children }: Props) {
    return <Container>{children}</Container>;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    margin: -0.5rem;
`;

export default PageContainer;
