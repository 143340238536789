import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import styled from 'styled-components';

import LogoDarkBlue from '../../../assets/logos/Dark blue.svg';
import { Section } from '../../../enums/Section';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { MOBILE_WIDTH } from '../../../hooks/useWindowDimensions';
import { open } from '../../../store/contact-form/contactFormSlice';
import L5button from '../../ui-elements/L5button';
import BenefitList from './BenefitList';

function Intro() {
    const [t] = useTranslation('intro');
    const dispatch = useAppDispatch();

    return (
        <Container id={Section.INTRO} name={Section.INTRO}>
            <Logo src={LogoDarkBlue} />
            <Title>{t('your_team')}</Title>
            <Subtitle>{t('we_care')}</Subtitle>

            <L5button
                text={t('work_with_us')}
                onClick={() => dispatch(open())}
            />

            <BenefitList />

            {/*<Link*/}
            {/*    to={Section.SERVICES}*/}
            {/*    activeClass="active"*/}
            {/*    spy={true}*/}
            {/*    smooth={true}*/}
            {/*    delay={200}*/}
            {/*    isDynamic={true}*/}
            {/*    offset={-100}*/}
            {/*>*/}
            {/*    <L5button text={t('expertise')} onClick={() => {}} />*/}
            {/*</Link>*/}
        </Container>
    );
}

const Container = styled(Element)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    margin: 80px 0 0 0;
    padding: 15px;

    @media (max-width: ${MOBILE_WIDTH}px) {
        margin: 8vh 0 0 0;
    }
`;

const Logo = styled.img`
    object-fit: contain;
    width: 50%;
`;

const Title = styled.p`
    font-size: 80px;
    text-align: center;
    margin: 20px 0 0 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
        font-size: 270%;
    }
`;

const Subtitle = styled.p`
    font-size: 25px;
    font-weight: lighter;
    margin: 60px;
    text-align: center;
`;

export default Intro;
