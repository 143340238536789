import React from 'react';
import styled from 'styled-components';

import { MOBILE_WIDTH } from '../../hooks/useWindowDimensions';
import { Colors } from '../../utils/Colors';

interface Props {
    large?: boolean;
    label: string;
}

function Label({ large, label }: Props) {
    return <Text large={large}>{label}</Text>;
}

const Text = styled.p<{ large: boolean | undefined }>`
    color: ${Colors.white};

    @media (max-width: ${MOBILE_WIDTH}px) {
        font-size: ${(props) => (props.large ? '14px' : '15px')};
    }
`;

export default Label;
